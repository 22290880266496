
import AccountAccessView from '@/components/AccountAccessView.vue'
import BussinessView from '@/components/BussinessView.vue'
import CartBannerView from '@/components/CartBannerView.vue'
import CartView from '@/components/CartView.vue'
import MenuItemDetailsView from '@/components/menu/MenuItemDetailsView.vue'
import MenuSetDetailsView from '@/components/menu/MenuSetDetailsView.vue'
import MenuListView from '@/components/MenuListView.vue'
import OngoingOrdersView from '@/components/OngoingOrdersView.vue'
import { Cart } from '@/model/Cart'
import { MenuItemModel, MenuModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { useDisplay } from 'vuetify'

@Options({
  components: { MenuListView, BussinessView, CartView, CartBannerView, OngoingOrdersView, MenuItemDetailsView, AccountAccessView, MenuSetDetailsView }
})
export default class HomeView extends Vue {
  displayBasketDialog = false
  displayItemDetailsDialog = false
  tab = ''

  get menu(): MenuModel | null {
    return this.$store.state.menu
  }

  get cart(): Cart | null {
    return this.$store.state.cart
  }

  get menuListCols(): number {
    const { smAndDown } = useDisplay()
    return smAndDown.value ? 12 : 7
  }

  get displayCartContent(): boolean {
    const { smAndDown } = useDisplay()
    return !smAndDown.value
  }

  get showDialogAsFullScreen(): boolean {
    const { smAndDown } = useDisplay()
    return smAndDown.value
  }

  get itemDetails(): MenuItemModel | null {
    if (!this.menu) {
      return null
    }
    if (this.$route.name === 'menu_item') {
      const id = this.$route.params.item_id as string
      return this.menu.getItemById(id)
    }
    return null
  }

  dismissItemDetails(): void {
    this.displayItemDetailsDialog = false
  }

  showBasketDialog(show: boolean): void {
    this.displayBasketDialog = show
  }

  onSectionIntersection(sectionId: string) {
    this.tab = sectionId
  }

  tabClicked(sectionId: string) {
    (this.$refs.menu_list as MenuListView).scrollToSection(sectionId)
    this.tab = sectionId
  }

  mounted(): void {
    this.$store.dispatch('fetchMenu', this.$route.params.id)
    let setupMenuDone = false
    this.displayItemDetailsDialog = this.$route.name === 'menu_item'

    this.$watch(() => this.$store.state.menu, (menu) => {
      document.title = `Kmandi | ${menu.business.title}`
      if (!setupMenuDone) {
        setupMenuDone = true
        if (this.itemDetails != null) {
          this.displayItemDetailsDialog = true
        }
      }
    })

    this.$watch(() => this.$route, (r) => {
      const routerName = r.name
      this.displayItemDetailsDialog = (routerName === 'menu_item')
    })

    this.$watch(() => this.displayItemDetailsDialog, (displayItemDetailsDialog) => {
      const navigateBack = !displayItemDetailsDialog
      if (navigateBack) {
        this.$router.push({ name: 'home', params: { id: this.$route.params.id, slug: this.$route.params.slug } })
      }
    })
  }
}

