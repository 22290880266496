
import { Cart, CartSingleItem } from '@/model/Cart'
import { MenuDisplayStyle, MenuItemModel } from '@/model/Menu'
import { Options, Vue } from 'vue-class-component'
import { RouteParamsRaw, useRoute } from 'vue-router'
import MenuItemStandardView from './MenuItemStandardView.vue'
import MenuItemCompactView from './MenuItemCompactView.vue'

@Options({
  props: {
    item: MenuItemModel,
    cart: Cart,
    displayStyle: MenuDisplayStyle
  },
  components: { MenuItemStandardView, MenuItemCompactView }
})
export default class MenuItemView extends Vue {
  item!: MenuItemModel
  displayStyle!: MenuDisplayStyle
  cart?: Cart

  get countOnCart(): number {
    return this.cart?.getItem(this.item.id)?.count || 0
  }

  get detailsParam(): RouteParamsRaw {
    const route = useRoute()
    return {
      id: route.params.id,
      slug: route.params.slug,
      item_id: this.item.id
    }
  }

  get displayCompact(): boolean {
    return this.displayStyle === MenuDisplayStyle.COMPACT
  }

  get displayCompactWithoutImage(): boolean {
    return this.displayStyle === MenuDisplayStyle.COMPACT_WITHOUT_IMAGE
  }

  onAddToCart(): void {
    this.$store.dispatch('addToCart', new CartSingleItem(this.item.id, 1))
  }

  onMinusFromCart(): void {
    this.$store.dispatch('minusFromCart', this.item.id)
  }
}
