import { userRepository } from '@/data/UserRepository'
import { sendNotificationToken } from '@/data/api/api'
import { getNotificationToken } from '@/notification/fcm.notification'
import { requestNotificationPermission } from '@/notification/notification'
import { withRetry } from '@/utils/promises.utils'

export class SendNotificationTokenService {
  async sendNotificationToken(): Promise<void> {
    const userId = userRepository.getUserId()
    if (!userId) {
      return
    }
    await requestNotificationPermission()
    const token = await withRetry<string>(() => getNotificationToken(), 5, 500)
    if (localStorage.getItem('last_notification_token') === token) {
      return
    }
    await sendNotificationToken(userId, token)
    localStorage.setItem('last_notification_token', token)
  }
}
