
import ErrorStateView from '@/components/ErrorStateView.vue'
import { CheckoutRepository } from '@/data/CheckoutRepository'
import { formatDuration } from '@/formatter/DateFormatter'
import { validatePhoneNumber } from '@/locale/PhoneNumber'
import { MenuModel } from '@/model/Menu'
import { MenuCartItem } from '@/model/MenuCartItem'
import { OrderCheckoutEvent } from '@/model/OrderCheckout'
import { OrderStatus } from '@/model/OrderStatus'
import { VForm } from '@/plugins/vuetify'
import router from '@/router'
import { MenuCartItemsService } from '@/service/MenuCartItemsService'
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { userRepository } from '@/data/UserRepository'
import { withRetry } from '@/utils/promises.utils'
import { AxiosError, HttpStatusCode } from 'axios'
import AccountAccessView from '@/components/AccountAccessView.vue'

@Options({
  components: { ErrorStateView, AccountAccessView }
})
export default class CartView extends Vue {
  menuCartItemsService = new MenuCartItemsService()
  nameInput = ''
  commentsInput = ''
  phoneNumberInput = ''
  valid = true
  checkingSession = false
  errorCheckSession = false

  get displayErrorMessage(): boolean {
    return this.$store.state.orderStatus === OrderStatus.Error
  }

  set displayErrorMessage(display: boolean) {
    if (!display) {
      this.$store.commit('SET_ORDER_STATUS', null)
    }
  }

  get menu(): MenuModel {
    return this.$store.state.menu
  }

  mounted(): void {
    const route = useRoute()
    this.$watch(() => this.$store.getters.currentMenu, () => {
      this.checkSession()
    })
    this.$watch(() => this.$store.getters.activeUserName, () => {
      this.checkSession()
    })
    this.$store.dispatch('fetchMenu', route.params.id)
    const checkoutRepository = new CheckoutRepository()
    const lastOrder = checkoutRepository.getLastSavedOrderCheckout()
    if (lastOrder) {
      this.nameInput = lastOrder.name
      this.phoneNumberInput = lastOrder.phoneNumber
    }
    this.$store.dispatch('resetOrderStatus')
  }

  checkSession() {
    if (!this.menu || !this.menu.business.requiresLoginToOrder) {
      this.checkingSession = false
      return
    }
    if (this.checkingSession) {
      return
    }
    this.errorCheckSession = false
    this.checkingSession = true

    withRetry<boolean>(() => userRepository.checkOrRefreshToken(), 5, 500)
      .then((success) => {
        if (!success) {
          this.$router.replace({ name: 'login', query: { redirect_url: this.$route.fullPath } })
        } else {
          this.checkingSession = false
        }
      })
      .catch((e) => {
        this.errorCheckSession = true
        if ((e as AxiosError)?.response?.status === HttpStatusCode.Unauthorized) {
          this.$router.replace({ name: 'login', query: { redirect_url: this.$route.fullPath } })
        }
      })
  }

  hasData(): boolean {
    return this.$store && this.$store.state.menu != null && this.$store.state.cart != null
  }

  cartItems(): MenuCartItem[] {
    if (!this.hasData()) {
      return []
    }
    if (this.$store.state.cart?.isEmpty()) {
      this.backToHome()
      return []
    }
    return this.menuCartItemsService.getMenuCartItems(this.$store.state.cart, this.$store.state.menu)
  }

  goToHome() {
    const slug = this.$store.state.menu?.business?.slug
    this.$router.push({ name: 'home', params: { id: this.$route.params.id, slug: slug } })
  }

  backToHome() {
    const slug = this.$store.state.menu?.business?.slug
    this.$router.replace({ name: 'home', params: { id: this.$route.params.id, slug: slug } })
  }

  totalCart(): string {
    return this.menuCartItemsService.getCartTotalFormatted(this.$store.state.cart, this.$store.state.menu)
  }

  phoneNumberRules() {
    if (!this.menu.business.askClientPhoneNumber) {
      return [true]
    }
    if (!this.phoneNumberInput) {
      return ['This field is required']
    }
    if (!validatePhoneNumber(this.phoneNumberInput, this.menu.business.locale)) {
      return ['This phone is not valid']
    }
    return [true]
  }

  nameRules() {
    if (!this.nameInput) {
      return ['This field is required']
    }
    return [true]
  }

  commentsRules() {
    if (this.commentsInput.length > 500) {
      return ['This field is too long']
    }
    return [true]
  }

  isLoading(): boolean {
    return this.$store.state.orderStatus === OrderStatus.Creating || this.checkingSession
  }

  isError(): boolean {
    return this.$store.state.orderStatus === OrderStatus.Error
  }

  get processingTimeMinutes(): string {
    return formatDuration(this.menu.business.processingTimeMinutes)
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.$store.watch(() => this.$store.getters.currentOrder, order => {
        console.log('Wiss order', order)
        if (order) {
          router.push({ name: 'order', params: { id: order.uuid }, replace: true })
        }
      })
      const phoneNumberInput = this.menu.business.askClientPhoneNumber ? this.phoneNumberInput : null
      this.$store.dispatch('sendOrder', new OrderCheckoutEvent(this.nameInput, phoneNumberInput, this.commentsInput.trim()))
    }
  }
}

