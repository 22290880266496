/* eslint no-useless-constructor:0 */

import { Order, OrderStatus } from '@/model/OrderCheckout'
import { BusinessModelDTO, mapToBusinessModel } from './BusinessModel.dto'
import { CartItemContentDTO, CartItemDTO, mapToCartItemDetails } from './Cart.dto'
import { formatPrice } from '../CurrencyFormatter'

export class OrderCheckoutDTO {
  constructor(
    readonly uuid: string,
    readonly userName: string,
    readonly cart: CartItemDTO[],
    readonly userPhone?: string,
    readonly comments?: string,
    readonly userId?: string,
  ) { }
}

export class OrderDTO {
  constructor(
    readonly id: number,
    readonly number: number,
    readonly uuid: string,
    readonly userId: string,
    readonly userName: string,
    readonly userPhone: string,
    readonly status: string,
    readonly createdAt: string,
    readonly cart: CartItemContentDTO[],
    readonly totalPrice: number,
    readonly business: BusinessModelDTO,
    readonly etaDate?: string,
    readonly comments?: string
  ) { }
}

function mapToOrderStatus(status: string): OrderStatus {
  const map: { [key: string]: OrderStatus } = {
    received: OrderStatus.received,
    in_progress: OrderStatus.inProgress,
    ready_to_pickup: OrderStatus.readyToPickup,
    done: OrderStatus.done,
    canceled_by_user: OrderStatus.canceledByUser,
    canceled: OrderStatus.canceled
  }
  return map[status] ?? OrderStatus.received
}

export function mapToOrder(dto: OrderDTO): Order {
  return new Order(
    dto.id,
    dto.number,
    dto.uuid,
    dto.userName,
    dto.userPhone,
    mapToCartItemDetails(dto.cart, dto.business.locale),
    formatPrice(dto.totalPrice, dto.business.locale),
    mapToOrderStatus(dto.status),
    new Date(dto.createdAt),
    mapToBusinessModel(dto.business),
    dto.etaDate != null ? new Date(dto.etaDate) : null,
    dto.comments
  )
}
