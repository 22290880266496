
import AccountAccessView from '@/components/AccountAccessView.vue'
import { formatDateTime } from '@/formatter/DateFormatter'
import { BusinessModel } from '@/model/Menu'
import { Order } from '@/model/OrderCheckout'
import { getOrderStatus } from '@/viewutils/OrderFormatter'
import { Options, Vue } from 'vue-class-component'
import { useRoute } from 'vue-router'

@Options({
  components: { AccountAccessView }
})
export default class AllOrdersView extends Vue {
  get orders(): Order[] {
    return this.$store.state.allOrders ?? []
  }

  get business(): BusinessModel | null {
    return this.$store.state.menu?.business
  }

  goToHome() {
    const slug = this.$store.state.menu?.business?.slug
    this.$router.push({ name: 'home', params: { id: this.$route.params.id, slug: slug } })
  }

  mounted(): void {
    const route = useRoute()
    const id = route.params['id']
    this.$store.dispatch('fetchAllOrders', id)
    if (!this.business) {
      this.$store.dispatch('fetchMenu', id)
    }
  }

  formattedOrderDate(order: Order): string | null {
    return formatDateTime(order.createdAt)
  }

  orderStatus(order: Order): string {
    return getOrderStatus(order)
  }
}
