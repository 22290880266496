import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "text-left" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "text-caption text-disabled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_AccountAccessView = _resolveComponent("AccountAccessView")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.business)
      ? (_openBlock(), _createBlock(_component_v_app_bar, {
          key: 0,
          dark: "",
          flat: "",
          color: "blue-grey-darken-4",
          align: "left",
          class: "pr-2"
        }, {
          prepend: _withCtx(() => [
            _createVNode(_component_v_btn, {
              icon: "mdi-home",
              onClick: _ctx.goToHome
            }, null, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_toolbar_title, { class: "font-weight-black" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.business.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_AccountAccessView)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_main, { class: "text-left" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
          return (_openBlock(), _createElementBlock("div", {
            key: order.id,
            class: "pl-4 pr-4"
          }, [
            _createVNode(_component_v_row, {
              lign: "center",
              justify: "center",
              class: "fill-width"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: { name: 'order', params: { id: order.uuid } },
                  custom: ""
                }, {
                  default: _withCtx(({ navigate }) => [
                    _createVNode(_component_v_card, {
                      class: "pa-4 ma-4 rounded-xl",
                      width: "700",
                      onClick: navigate
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_1, [
                          _createElementVNode("span", {
                            innerHTML: _ctx.orderStatus(order)
                          }, null, 8, _hoisted_2)
                        ]),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          color: "black"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.cart, (item) => {
                              return (_openBlock(), _createBlock(_component_v_row, {
                                key: item.itemId,
                                class: "pt-1 pb-1 pl-0 pr-0",
                                justify: "space-between"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_col, { class: "pa-0 ma-0 text-left" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", null, _toDisplayString(item.count) + "x ", 1),
                                      _createElementVNode("span", null, _toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.totalPrice), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128)),
                            _createVNode(_component_v_row, {
                              class: "pb-1 font-weight-bold mt-3",
                              justify: "space-between"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { class: "pa-0 ma-0 text-left" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('total')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { class: "pa-0 ma-0 text-right strong" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(order.totalPrice), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.formattedOrderDate(order)), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}