/* eslint no-useless-constructor:0 */

import { CartItemDetails } from './Cart'
import { BusinessModel } from './Menu'

export class OrderCheckoutEvent {
  constructor(
    readonly name: string,
    readonly phoneNumber?: string,
    readonly comments?: string
  ) { }
}

export enum OrderStatus {
  received,
  inProgress,
  readyToPickup,
  done,
  canceledByUser,
  canceled
 }

export class Order {
  constructor(
    readonly id: number,
    readonly number: number,
    readonly uuid: string,
    readonly userName: string,
    readonly userPhone: string,
    readonly cart: CartItemDetails[],
    readonly totalPrice: string,
    readonly status: OrderStatus,
    readonly createdAt: Date,
    readonly business: BusinessModel,
    readonly etaDate?: Date,
    readonly comments?: string
  ) { }
}
