
import { Options, Vue } from 'vue-class-component'
import CentralLayout from '@/components/CentralLayout.vue'

@Options({
  components: { CentralLayout },
  props: {
    errorMessage: String,
    done: Boolean
  }
})
export default class ConnexionLayout extends Vue {
  done: boolean
  errorMessage?: string
}
