import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8032a92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "ma-6 pb-12 mb-12",
  style: {"position":"relative"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountAccessView = _resolveComponent("AccountAccessView")!
  const _component_BussinessView = _resolveComponent("BussinessView")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_MenuListView = _resolveComponent("MenuListView")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CartView = _resolveComponent("CartView")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CartBannerView = _resolveComponent("CartBannerView")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_OngoingOrdersView = _resolveComponent("OngoingOrdersView")!
  const _component_MenuItemDetailsView = _resolveComponent("MenuItemDetailsView")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, null, {
    default: _withCtx(() => [
      (_ctx.menu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_BussinessView, {
              business: _ctx.menu.business
            }, {
              "top-right": _withCtx(() => [
                _createVNode(_component_AccountAccessView)
              ]),
              _: 1
            }, 8, ["business"]),
            _createVNode(_component_v_tabs, {
              modelValue: _ctx.tab,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
              "align-tabs": "center",
              class: "overlay-nav",
              "show-arrows": "",
              "bg-color": "background",
              color: "primary"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.content, (section) => {
                  return (_openBlock(), _createBlock(_component_v_tab, {
                    value: section.id,
                    key: section.id,
                    onClick: ($event: any) => (_ctx.tabClicked(section.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(section.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["value", "onClick"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_row, {
                "no-gutters": "",
                width: "100%",
                class: "d-flex"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: _ctx.menuListCols }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MenuListView, {
                        menu: _ctx.menu,
                        cart: _ctx.cart,
                        ref: "menu_list",
                        onSectionIntersection: _ctx.onSectionIntersection
                      }, null, 8, ["menu", "cart", "onSectionIntersection"])
                    ]),
                    _: 1
                  }, 8, ["cols"]),
                  (_ctx.displayCartContent)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        class: "ml-12 mt-12",
                        cols: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, { class: "rounded-xl" }, {
                            default: _withCtx(() => [
                              (_ctx.cart && _ctx.menu)
                                ? (_openBlock(), _createBlock(_component_CartView, {
                                    key: 0,
                                    cart: _ctx.cart,
                                    menu: _ctx.menu
                                  }, null, 8, ["cart", "menu"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_v_col, {
              cols: "12",
              class: "fixed-bottom pa-5"
            }, {
              default: _withCtx(() => [
                (!_ctx.displayCartContent)
                  ? (_openBlock(), _createBlock(_component_v_dialog, {
                      key: 0,
                      modelValue: _ctx.displayBasketDialog,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displayBasketDialog) = $event)),
                      width: "",
                      transition: "dialog-bottom-transition",
                      fullscreen: "",
                      scrim: false
                    }, {
                      activator: _withCtx(({ props }) => [
                        (_ctx.cart && _ctx.menu)
                          ? (_openBlock(), _createBlock(_component_CartBannerView, _mergeProps({
                              key: 0,
                              cart: _ctx.cart,
                              menu: _ctx.menu,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showBasketDialog(true)))
                            }, props), null, 16, ["cart", "menu"]))
                          : _createCommentVNode("", true)
                      ]),
                      default: _withCtx(() => [
                        (_ctx.cart && _ctx.menu)
                          ? (_openBlock(), _createBlock(_component_CartView, {
                              key: 0,
                              cart: _ctx.cart,
                              menu: _ctx.menu,
                              stickyCheckoutBtn: true,
                              height: "100%"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_toolbar, { color: "primary" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_app_bar_nav_icon, {
                                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showBasketDialog(false)))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_icon, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode("mdi-close")
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_toolbar_title, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('basket')), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["cart", "menu"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_OngoingOrdersView)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.itemDetails != null)
        ? (_openBlock(), _createBlock(_component_v_dialog, {
            key: 1,
            modelValue: _ctx.displayItemDetailsDialog,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayItemDetailsDialog) = $event)),
            fullscreen: _ctx.showDialogAsFullScreen,
            "min-width": "300",
            "max-width": "600"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MenuItemDetailsView, {
                item: _ctx.itemDetails,
                onDismiss: _ctx.dismissItemDetails,
                altImage: _ctx.menu.business.image,
                align: "center"
              }, null, 8, ["item", "onDismiss", "altImage"])
            ]),
            _: 1
          }, 8, ["modelValue", "fullscreen"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}