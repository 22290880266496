import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-h4 pt-1 pb-1 pl-2 pr-2" }
const _hoisted_2 = { style: {"white-space":"normal"} }
const _hoisted_3 = {
  key: 0,
  class: "text-left",
  style: {"white-space":"pre-line"}
}
const _hoisted_4 = { class: "text-body-1" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { style: {"white-space":"normal"} }
const _hoisted_8 = {
  key: 0,
  class: "pa-0 mt-4",
  style: {"white-space":"pre-line"}
}
const _hoisted_9 = { class: "text-body-1" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_card, {
      class: "rounded-xl pb-4",
      width: "100%"
    }, {
      default: _withCtx(() => [
        (_ctx.mobileDisplay)
          ? (_openBlock(), _createBlock(_component_v_col, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  width: "100%",
                  "max-height": "300",
                  "aspect-ratio": "1/1",
                  cover: "",
                  src: _ctx.business.image
                }, null, 8, ["src"]),
                _createVNode(_component_v_row, {
                  justify: "space-between",
                  class: "mt-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      align: "left",
                      cols: "6"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.business.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      align: "right",
                      cols: "auto"
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "top-right")
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                }),
                _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left mb-0 pb-0 mt-2" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      href: _ctx.business.addressUrl,
                      target: "_blank",
                      variant: "text",
                      color: "blue-darken-3",
                      class: "text-left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, {
                          icon: "mdi-map-marker-outline pr-4",
                          size: "xsmall"
                        }),
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.business.address), 1),
                        _createVNode(_component_v_icon, {
                          icon: "mdi-chevron-right",
                          class: "ml-1"
                        })
                      ]),
                      _: 1
                    }, 8, ["href"])
                  ]),
                  _: 1
                }),
                (_ctx.business.description)
                  ? (_openBlock(), _createElementBlock("blockquote", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.business.description), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.business.displayEta)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left b-0 pb-0" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.processingTimeMinutes })
                          }, null, 8, _hoisted_6)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }))
          : (_openBlock(), _createBlock(_component_v_row, {
              key: 1,
              justify: "space-between"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "3",
                  class: "pa-12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      width: "100%",
                      height: "100%",
                      "max-height": "300",
                      "aspect-ratio": "1/1",
                      cover: "",
                      src: _ctx.business.image
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "7",
                  class: "text-left pt-12 pl-0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "text-h4 pa-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.business.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_text, { class: "text-subtitle-1 text-left pl-0 mb-0 pb-0 mt-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          href: _ctx.business.addressUrl,
                          target: "_blank",
                          variant: "plain",
                          color: "blue-darken-1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, {
                              icon: "mdi-map-marker-outline pr-4",
                              size: "xsmall"
                            }),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.business.address), 1),
                            _createVNode(_component_v_icon, {
                              icon: "mdi-chevron-right",
                              class: "ml-1"
                            })
                          ]),
                          _: 1
                        }, 8, ["href"])
                      ]),
                      _: 1
                    }),
                    (_ctx.business.description)
                      ? (_openBlock(), _createElementBlock("blockquote", _hoisted_8, [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.business.description), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.business.displayEta)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                          _createVNode(_component_v_card_text, { class: "text-body-2 mt-2 pa-0" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                innerHTML: _ctx.$t('estimated_waiting_time', { time: _ctx.processingTimeMinutes })
                              }, null, 8, _hoisted_11)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "auto",
                  class: "pt-12 mr-3"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "top-right")
                  ]),
                  _: 3
                })
              ]),
              _: 3
            }))
      ]),
      _: 3
    })
  ]))
}