import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  align: "center"
}
const _hoisted_2 = { class: "text-grey-darken-1 pt-2" }
const _hoisted_3 = { class: "text-body-2" }
const _hoisted_4 = { class: "text-h5 text-error font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createVNode(_component_v_sheet, { class: "pt-5 pb-5 pl-3 pr-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              (_ctx.cart.isEmpty())
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_v_icon, {
                      icon: "mdi-basket-outline",
                      size: "x-large",
                      color: "grey-darken-1"
                    }),
                    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('cart_empty_message')), 1)
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.cartItems(), (item) => {
                    return (_openBlock(), _createBlock(_component_v_row, {
                      key: item.cartItem.itemId,
                      class: "pt-1 pb-1 pl-0 pr-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          class: "pa-0 ma-0 me-auto",
                          cols: "auto"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              class: "text-left text-subtitle-1 font-weight-medium pa-0 ma-0 me-auto",
                              cols: "auto"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_v_col, {
                          class: "pa-0 ma-0",
                          cols: "auto"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              class: "pa-0 ma-0",
                              cols: "auto"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_btn, {
                                  density: "compact",
                                  variant: "plain",
                                  icon: "mdi-minus-circle-outline",
                                  class: "z-index fab raised",
                                  onClick: ($event: any) => (_ctx.onMinusFromCart(item.cartItem.itemId))
                                }, null, 8, ["onClick"]),
                                _createElementVNode("span", _hoisted_3, _toDisplayString(item.cartItem.count), 1),
                                _createVNode(_component_v_btn, {
                                  density: "compact",
                                  variant: "plain",
                                  icon: "mdi-plus-circle-outline",
                                  class: "z-index fab raised",
                                  onClick: ($event: any) => (_ctx.onAddToCart(item.cartItem.itemId))
                                }, null, 8, ["onClick"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_v_col, {
                              class: "text-body-2 pa-0 ma-0",
                              cols: "auto"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.itemTotalPrice), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
              _createVNode(_component_v_divider, { class: "mt-5" }),
              _createVNode(_component_v_row, { class: "mt-5" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    class: "pa-0 ma-0 me-auto text-subtitle-1 font-weight-bold",
                    cols: "auto"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('total')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    class: "pa-0 ma-0 text-subtitle-1 font-weight-bold",
                    cols: "auto"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.totalCart()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.menu.business.isOpened)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    class: _normalizeClass(["mt-5 mr-12", { 'sticky-bottom': _ctx.stickyCheckoutBtn }]),
                    style: {}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: { name: 'checkout', params: { id: _ctx.menu.business.id } },
                        custom: ""
                      }, {
                        default: _withCtx(({ navigate }) => [
                          _createVNode(_component_v_btn, {
                            onClick: navigate,
                            variant: "flat",
                            block: "",
                            disabled: _ctx.cart.isEmpty(),
                            color: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('cart_go_to_checkout')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "disabled"])
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ]),
                    _: 1
                  }, 8, ["class"]))
                : (_openBlock(), _createBlock(_component_v_col, { key: 3 }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('closed_message')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}