import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-h5 text-capitalize" }
const _hoisted_2 = { class: "text-capitalize pl-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event))
  }, {
    activator: _withCtx(({ props }) => [
      (_ctx.userName)
        ? (_openBlock(), _createBlock(_component_v_card, _mergeProps({
            key: 0,
            variant: "outlined",
            align: "center",
            class: "pt-1 pb-1 pl-2 pr-2 rounded-xl"
          }, props, { id: "account_card" }), {
            default: _withCtx(() => [
              _createVNode(_component_v_avatar, { color: "primary" }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.initial), 1)
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.userName), 1)
            ]),
            _: 2
          }, 1040))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_sheet, { class: "pt-2 pb-2 bg-surface-variant" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            variant: "text",
            block: "",
            align: "left",
            class: "text-left bg-surface-variant",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('logout')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_v_btn, {
            variant: "text",
            block: "",
            align: "left",
            class: "text-left bg-surface-variant",
            to: { name: 'all-orders', params: _ctx.allOrdersParam }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('my_oders')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}