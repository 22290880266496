<template>
  <v-app>
    <!-- <nav>
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </v-app>
</template>

<style lang="scss">
.v-application .v-card {
  box-shadow: 0 2px 4px rgba(189, 211, 232, 0.2) !important;
}

.v-btn {
  border-radius: 28px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0px;
  left: 0px;
}

.fixed-bottom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.semi-transparent {
  color: rgba(0, 0, 0, 0.3);
  opacity: 0.5;
}

.highlight {
  position: relative;
}

.highlight::before {
  background-color: #03dac5ac;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 70%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(-2deg);
}

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.invisible {
  visibility: hidden;
}

a.link {
  color: rgb(86, 126, 178);
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  padding: .5em;
  margin-left: -.5em;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-bottom: 3px solid rgb(86, 126, 178);
    border-radius: 1em;
    bottom: .3em;
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:before {
    width: 1em;
    transform-origin: left;
  }

  &:after {
    width: 82%;
    left: 1em;
    transform: translateX(110%);
  }

  &:hover:before {
    transform: scaleX(0.3);
  }

  &:hover:after {
    transform: translateX(0);
  }
}

blockquote {
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "“" "”" "‘" "’";
  font-family: Georgia, "Times New Roman", Times, serif;
  border: none;
}

blockquote::before {
  color: #018786;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

blockquote footer {
  color: #555;
  font-size: 0.9em;
}
</style>
