
import { Options, Vue } from 'vue-class-component'
import { VForm } from 'vuetify/lib/components/index.mjs'
import { isEmailValid, isPasswordValid } from '@/form/rules'
import { useI18n } from 'vue-i18n'
import { userRepository } from '@/data/UserRepository'
import ConnexionLayout from '@/components/ConnexionLayout.vue'
import { toHandlers } from 'vue'

@Options({
  components: { ConnexionLayout }
})
export default class ForgotPasswordView extends Vue {
  emailInput = ''
  valid = false
  done = false
  passwordVisible = false
  isLoading = false
  errorMessage?: string

  get canGoBack(): boolean {
    return this.$router.options.history.state.back != null
  }

  goBack() {
    this.$router.back()
  }

  emailRules() {
    const { t } = useI18n()
    if (!isEmailValid(this.emailInput)) {
      return [t('error_invalid_email')]
    }
    return [true]
  }

  async submit() {
    await (this.$refs.form as VForm).validate()
    if (this.valid) {
      this.isLoading = true
      this.errorMessage = null
      const url = this.$route.query.redirect_url as string
      userRepository.forgetPassword(this.emailInput, url)
        .then(() => {
          this.done = true
        })
        .catch((e) => {
          this.errorMessage = this.$t('error_invalid_credential')
          this.isLoading = false
        })
    }
  }
}
